const roundNumber = (num, format_key) => {
    const match = format_key && format_key.match(/number.dec.(\d+)/);
    return num && (+num.toFixed(match && match[0] !== undefined ? match[1] : 2));
};

// eslint-disable-next-line no-useless-escape
const isSafeRegex = /^[\.\,0-9\+\-xX\ \(\)\/\*]+$/;
const applyFormula = (valor, formula, format_key) => {
    let x = valor || 0;
    if (formula && isSafeRegex.test(formula)) {
    // the formulas uses 'x' as the original value. all inputs need to be named x
    // eslint-disable-next-line no-eval
        x = eval(formula);
    }
    return roundNumber(x, format_key);
};
const applyFormulas = (valor, formula1, formula2, format_key) => {
    let x = valor || 0;
    if (formula1 && formula2 && isSafeRegex.test(formula1) && isSafeRegex.test(formula2)) {
    // the formulas uses 'x' as the original value. all inputs need to be named x
    // eslint-disable-next-line no-eval
        x = eval(formula1);
        // eslint-disable-next-line no-eval
        x = eval(formula2);
    }
    return roundNumber(x, format_key);
};

export { applyFormula, applyFormulas };
