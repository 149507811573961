// check wiseconn-env-gen.js for build envs
const region = import.meta.env.VITE_REGION ?? "us-east-1";
const endpoint = import.meta.env.VITE_ENDPOINT ?? `https://qkud66oww6.execute-api.${region}.amazonaws.com/dev`;
const domain = import.meta.env.VITE_DOMAIN ?? (
    import.meta.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://admin.dropcontrol.com"
);

const wise_exports =  {
    "aws_project_region": region,
    "aws_cloud_logic_custom": [
        {
            "name": "apiAdminDropControl",
            "endpoint": endpoint,
            "region": region
        },
        {
            "name": "apiAdminDropControlNoAuth",
            "endpoint": endpoint,
            "region": region
        }
    ],
    "Auth": {
        "userPoolId": `${region}_Izgq043do`,
        "userPoolWebClientId": "459brd05li2akb10d8g66frs50",
        "region": region,
        "oauth": {
            "domain": "auth.wiseconn.com",
            "redirectSignIn": domain,
            "redirectSignOut": domain,
            "responseType": "code"
        }
    }
};

export default wise_exports;
