import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import ButtonPrime from "../PrimeComponents/ButtonPrime";
import DropdownPrime from "../PrimeComponents/DropdownPrime";

const redirectAcc = (history, navigate, ddlObj, onlyAccount) => {
    const id = onlyAccount ? onlyAccount.id : (ddlObj && ddlObj.value);

    if (id) {
        const idParts = id.split("-");
        const location = history.location; // we use history.location to get the exact url at this moment (and not the one at render init)
        const currentUrl = `${location.pathname}${location.search}`;
        const newPath = "/account";
        const newSearch = `?account=${idParts[1]}`;
        const newUrl = `${newPath}${newSearch}`;
        if (currentUrl !== newUrl) {
            navigate({ pathname: newPath, search: newSearch });
        }
    }
};

const width = "95%";
const Selector = ({ history, navigate, dataSource, setFields,fields, redirectFarm, routeAccount, farmAccount, farmId }) => {
    const selectorObj = useRef({});
    const { t } = useTranslation();

    const ddlValue = useMemo(() => {
        const val = (routeAccount && `cuenta-${routeAccount}`) || farmAccount || null;
        if (selectorObj.current && selectorObj.current.value !== val) {
            selectorObj.current.value = val;
        }
        const _fields = dataSource?.find((d) => d.id===val);
        if(_fields) setFields(_fields?.subChild);
        return val;
    }, [routeAccount, farmAccount]);

    useEffect(() => {
        if(dataSource && !fields){
            const val = (routeAccount && `cuenta-${routeAccount}`) || farmAccount || null;
            if (selectorObj.current && selectorObj.current.value !== val) {
                selectorObj.current.value = val;
            }
            const _fields = dataSource?.find((d) => d.id===val);
            if(_fields) setFields(_fields?.subChild);
        }
    }, [dataSource]);

    const onlyAccount = useMemo(() => {
        const onlyOne = dataSource && (dataSource.length === 1);
        if (!onlyOne) return null;
        const onlyAccount = dataSource[0];

        setFields(onlyAccount.subChild);
        const farmInAccounts = onlyAccount.subChild?.some(f => f.id === `campo-${farmId}`);
        if ((ddlValue !== null && ddlValue !== onlyAccount.id) || (ddlValue === null && !farmInAccounts)){
            redirectFarm(history, navigate, onlyAccount.subChild[0]);
        }
        return onlyAccount;
    // this is the initial redirect, and it's only done when we get the dataSource
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSource]);

    return (
        <div className='row'>
            {onlyAccount ?
                <div className='ddl-container col-10'>
                    <span style={{ width, cursor: "default" }} className="e-input-group e-control-wrapper e-ddl e-valid-input">
                        <input id="cuentas-ddl" value={onlyAccount.name} className="e-control e-dropdownlist e-input" />
                    </span>
                </div>
                :
                <div className={`${selectorObj && (selectorObj.current.value ? "ddl-container col-10" : "ddl-container col-12")}`}>
                    <DropdownPrime
                        accountDD
                        showClear={false}
                        disabled={!dataSource || !dataSource.length}
                        value={ddlValue}
                        onChange={(e) => {
                            selectorObj.current.value = dataSource.find(a => a.id === e.value);
                            if (selectorObj.current.value) {
                                selectorObj.current.value.subChild && selectorObj.current.value.subChild.sort((a, b) => a.name < b.name ? -1 : 1);
                                setTimeout(() => {
                                    setFields(selectorObj.current.value.subChild);
                                    if (ddlValue !== e.value.id) {
                                        redirectFarm(history, navigate, selectorObj.current.value.subChild[0]);
                                    }
                                }, 0);
                            }
                        }}
                        options={dataSource} optionLabel="name"
                        optionValue="id"
                        placeholder={t("accounts.accounts")}
                        name="ddlNutrientes"
                    />
                </div>}
            <div className={`icono-cuenta ${onlyAccount || (selectorObj && (selectorObj.current.value !== null)) ? "col-2" : "d-none"}`}>
                <div className='containerButtonAcc'>
                    <ButtonPrime id="accountInfo" className="primeBtn dark" t={t} tooltip={t("accounts.accInfo")} tooltipOptions={{ className:"tooltip-prime dark", position:"right"}} onClick={() => redirectAcc(history, navigate, selectorObj.current, onlyAccount)} >
                        <span className='iconExternalLink'
                            onClick={() => redirectAcc(history, navigate, selectorObj.current, onlyAccount)}
                        />
                    </ButtonPrime>
                </div>
            </div>
        </div>
    );
};

export default Selector;
