import withName from "../../hoc/withName";
import React from "react";
import { Tooltip } from "primereact/tooltip";

const TooltipPrime = ({ children = null, id,content=null,   ...tooltipProps }) => {

    const childWithId = React.Children.map(children, child => {
        return React.cloneElement(child, { id });
    });
    return (
        <>
            <Tooltip className="tooltip-prime" target={`#${id}`} {...tooltipProps} >
                {content}
            </Tooltip>
            {childWithId}
        </>
    );
};

export default withName(TooltipPrime, "TooltipPrime");
