// HOTFIX
import "./globalFix";

import { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";

import "./index.css";
import "./utils/format";
import App from "./App";
import "./amplifyConfig.js";
// import i18n (needs to be bundled ;))
import "./i18n";

import Loading from "../src/img/loading-borde-suave.gif";

const root = createRoot(document.getElementById("root"));
root.render(
    <StrictMode>
        <Suspense fallback={<div className='row-container'><img className='img-loading' src={Loading} alt="Loading..." /></div>}>
            <App />
        </Suspense>
    </StrictMode>
);
