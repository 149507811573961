import { useEffect, useRef } from "react";
import withName from "../../hoc/withName";
import injectScript from "../../utils/inject";

const placeSchema = {
    locality: {
        access: ["long_name"],
        name: "city"
    },
    administrative_area_level_1: {
        access: ["short_name"],
        name: "region"
    },
    country: {
        access: ["long_name", "short_name"],
        name: "country"
    }
};

const createPlaceObject = async (autocomplete, onChange) => {
    // Get the place details from the autocomplete object.
    const place = autocomplete.current && autocomplete.current.getPlace();
    const formatedPlace = {};

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    for (const component of place.address_components) {
        const addressType = component.types[0];

        if (placeSchema[addressType]) {
            let val;
            if (placeSchema[addressType].access.length === 1) {
                val = component[placeSchema[addressType].access[0]];
            } else {
                val = placeSchema[addressType].access.reduce((res, a) => {
                    res[a] = component[a];
                    return res;
                }, {});
            }
            formatedPlace[placeSchema[addressType].name] = val;
        }
    }

    onChange(formatedPlace);
};

const GooglePlacesAutocomplete = ({
    apiKey = "",
    language = "",
    placeholder = "",
    onChange = console.log,
    onLoadFailed = console.error
}) => {
    const autocomplete = useRef(null);

    const init = () => {
        if (!window.google) throw new Error("[react-google-places-autocomplete]: Google script not loaded");
        if (!window.google.maps) throw new Error("[react-google-places-autocomplete]: Google maps script not loaded");
        if (!window.google.maps.places) throw new Error("[react-google-places-autocomplete]: Google maps places script not loaded");

        autocomplete.current = new window.google.maps.places.Autocomplete(
            document.getElementById("autocomplete"),
            { types: ["country", "locality", "administrative_area_level_3"] }
        );
        autocomplete.current.setFields(["address_components"]);
        autocomplete.current.addListener("place_changed", () => createPlaceObject(autocomplete, onChange));
    };

    useEffect(() => {
        const initCall = async () => {
            try {
                if (apiKey) await injectScript(apiKey, language ? language : "en");
                init();
            } catch (error) {
                onLoadFailed(error);
            }
        };

        initCall();
    }, []);

    return (
        <div id="locationField">
            <input
                id="autocomplete"
                className="form-control new font13 text-center text-md-left"
                placeholder={placeholder}
                type="text"
            />
            <div id="placesAttrContainer" />
        </div>
    );
};

export default withName(GooglePlacesAutocomplete, "PlacesAuto");
