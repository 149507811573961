import { useState, Suspense } from "react";
import mundo from "../../img/world_icon.png";
import logo_dc from "../../img/logo-dropcontrol-1.png";
import { Auth } from "@aws-amplify/auth";

//import '../../../node_modules/font-awesome/css/font-awesome.min.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Loading from "../../img/loading-borde-suave.gif";
import getBaseLangs from "../../utils/language";


function Login({ dcEmail }) {
    const { t, i18n } = useTranslation();

    //datos del login
    const [userEmail, setUser] = useState(dcEmail || "");
    const [userPass, setPassword] = useState(" ");

    //mensaje de error y var para renderizar login o formForgotpass
    const msgInfoForgot = t("login.msgInfoForgot");
    const pHolderCode = t("login.pHolderCode");
    const pHolderPass = t("login.pHolderPass");
    const pHolderPassConfirm = t("login.pHolderPassConfirm");
    const changePassword = t("login.changePassword");

    const [showPassForNewUser, setShowPassForNewUser] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);

    const [showInvalidUser, setShowInvalidUser] = useState(false);
    const [showMsgEmail, setShowMsgEmail] = useState(false);
    const [showMsgPass, setShowMsgPass] = useState(false);

    const [showUserNotFound, setShowUserNotFound] = useState(false);
    const [showCodeSent, setShowCodeSent] = useState(false);
    const [showEnterCode, setShowEnterCode] = useState(false);
    const [showInvalidCode, setShowInvalidCode] = useState(false);
    const [showPassLength, setSetShowPassLength] = useState(false);
    const [showPassMatch, setShowPassMatch] = useState(false);
    const [showCodeException, setShowCodeException] = useState(false);
    const [showExpiredCodeException, setShowExpiredCodeException] = useState(false);
    const [showLimitExceededException, setShowLimitExceededException] = useState(false);
    const [showInvalidParameterException, setShowInvalidParameterException] = useState(false);
    const [showUserNotFoundException, setShowUserNotFoundException] = useState(false);
    const [showDefaultError, setShowDefaultError] = useState(false);
    const [showPassChanged, setShowPassChanged] = useState(false);

    const [showLogin, setShowLogin] = useState(true);
    const [showLoginForgot, setShowLoginForgot] = useState(false);

    //datos del forgotpass
    const [code, setCode] = useState(0);
    const [newPass, setNewPass] = useState("");
    const [rePass, setRePass] = useState("");



    async function resetSubmit() {
        if (newPass === null || newPass.length < 8) {
            setShowUserNotFound(false);
            setShowCodeSent(false);
            setShowEnterCode(false);
            setShowInvalidCode(false);
            setSetShowPassLength(true);
            setShowPassMatch(false);
            setShowCodeException(false);
            setShowLimitExceededException(false);
            setShowInvalidParameterException(false);
            setShowUserNotFoundException(false);
            setShowDefaultError(false);
            setShowExpiredCodeException(false);
            setShowPassChanged(false);
            return;
        }
        if (newPass !== rePass) {
            setShowUserNotFound(false);
            setShowPassChanged(false);
            setShowCodeSent(false);
            setShowEnterCode(false);
            setShowInvalidCode(false);
            setSetShowPassLength(false);
            setShowPassMatch(true);
            setShowCodeException(false);
            setShowLimitExceededException(false);
            setShowInvalidParameterException(false);
            setShowUserNotFoundException(false);
            setShowDefaultError(false);
            setShowExpiredCodeException(false);
            return;
        }
        try {
            setShowPassChanged(false);
            setShowUserNotFound(false);
            setShowCodeSent(false);
            setShowEnterCode(false);
            setShowInvalidCode(false);
            setSetShowPassLength(false);
            setShowPassMatch(false);
            setShowCodeException(false);
            setShowLimitExceededException(false);
            setShowInvalidParameterException(false);
            setShowUserNotFoundException(false);
            setShowDefaultError(false);
            setShowExpiredCodeException(false);

            await Auth.completeNewPassword(
                currentUser, newPass,
                {
                    name: currentUser.challengeParam.userAttributes.name,
                    family_name: currentUser.challengeParam.userAttributes.family_name,
                });
        } catch (err) {
            console.log("error", err);
            if (err != null && err.code != null) {
                switch (err.code) {
                    case "ExpiredCodeException":
                        await Auth.forgotPassword(userEmail);
                        setShowUserNotFound(false);
                        setShowCodeSent(false);
                        setShowEnterCode(false);
                        setShowInvalidCode(false);
                        setSetShowPassLength(false);
                        setShowPassMatch(false);
                        setShowCodeException(false);
                        setShowLimitExceededException(false);
                        setShowInvalidParameterException(false);
                        setShowUserNotFoundException(false);
                        setShowDefaultError(false);
                        setShowExpiredCodeException(true);
                        setShowPassChanged(false);
                        break;
                    case "LimitExceededException":
                        setShowUserNotFound(false);
                        setShowCodeSent(false);
                        setShowEnterCode(false);
                        setShowInvalidCode(false);
                        setSetShowPassLength(false);
                        setShowPassMatch(false);
                        setShowCodeException(false);
                        setShowLimitExceededException(true);
                        setShowInvalidParameterException(false);
                        setShowUserNotFoundException(false);
                        setShowDefaultError(false);
                        setShowExpiredCodeException(false);
                        setShowPassChanged(false);
                        break;
                    case "UserNotFoundException":
                        setShowUserNotFound(false);
                        setShowCodeSent(false);
                        setShowEnterCode(false);
                        setShowInvalidCode(false);
                        setSetShowPassLength(false);
                        setShowPassMatch(false);
                        setShowCodeException(false);
                        setShowLimitExceededException(false);
                        setShowInvalidParameterException(false);
                        setShowUserNotFoundException(true);
                        setShowDefaultError(false);
                        setShowExpiredCodeException(false);
                        setShowPassChanged(false);
                        break;
                    case "InvalidParameterException":
                        setShowUserNotFound(false);
                        setShowCodeSent(false);
                        setShowEnterCode(false);
                        setShowInvalidCode(false);
                        setSetShowPassLength(false);
                        setShowPassMatch(false);
                        setShowCodeException(false);
                        setShowLimitExceededException(false);
                        setShowInvalidParameterException(true);
                        setShowUserNotFoundException(false);
                        setShowDefaultError(false);
                        setShowExpiredCodeException(false);
                        setShowPassChanged(false);
                        break;
                    case "CodeMismatchException":
                        setShowUserNotFound(false);
                        setShowCodeSent(false);
                        setShowEnterCode(false);
                        setShowInvalidCode(true);
                        setSetShowPassLength(false);
                        setShowPassMatch(false);
                        setShowCodeException(false);
                        setShowLimitExceededException(false);
                        setShowInvalidParameterException(false);
                        setShowUserNotFoundException(false);
                        setShowDefaultError(false);
                        setShowExpiredCodeException(false);
                        setShowPassChanged(false);
                        break;
                    default:
                        setShowUserNotFound(false);
                        setShowCodeSent(false);
                        setShowEnterCode(false);
                        setShowInvalidCode(false);
                        setSetShowPassLength(false);
                        setShowPassMatch(false);
                        setShowCodeException(false);
                        setShowLimitExceededException(false);
                        setShowInvalidParameterException(false);
                        setShowUserNotFoundException(false);
                        setShowDefaultError(true);
                        setShowExpiredCodeException(false);
                        setShowPassChanged(false);
                        break;
                }
            }
        }
    }

    async function handleChange(name, value) {
        switch (name) {
            case "usuario":
                if (value === null || value === "") {
                    value = " ";
                    await setUser(value);
                } else {
                    await setUser(value);
                }
                break;
            case "contraseña":
                await setPassword(value);
                break;
            case "code":
                if (value === null || value === "" || value === undefined) {
                    value = " ";
                    await setCode(value);
                } else {
                    await setCode(value);
                }
                break;
            case "newpassword":
                if (value === null || value === "") {
                    value = " ";
                    await setNewPass(value);
                } else {
                    await setNewPass(value);
                }
                break;
            case "repassword":
                if (value === null || value === "") {
                    value = " ";
                    await setRePass(value);
                } else {
                    await setRePass(value);
                }
                break;
            default:
                console.log("handlechange error");
        }
    }

    async function showForgot() {
        if (userEmail === null || userEmail === " ") {
            setShowMsgEmail(true);
        } else {
            try {
                await Auth.forgotPassword(userEmail);
                setShowLogin(false);
                setShowLoginForgot(true);
                setShowCodeSent(true);
                setShowInvalidUser(false);

            } catch (err) {
                console.error(err);
                switch (err.code) {
                    case "LimitExceededException":
                        setShowUserNotFound(false);
                        setShowCodeSent(false);
                        setShowEnterCode(false);
                        setShowInvalidCode(false);
                        setSetShowPassLength(false);
                        setShowPassMatch(false);
                        setShowCodeException(false);
                        setShowLimitExceededException(true);
                        setShowInvalidParameterException(false);
                        setShowUserNotFoundException(false);
                        setShowDefaultError(false);
                        setShowExpiredCodeException(false);
                        setShowPassChanged(false);
                        break;
                    case "UserNotFoundException":
                        setShowUserNotFound(true);
                        setShowCodeSent(false);
                        setShowEnterCode(false);
                        setShowInvalidCode(false);
                        setSetShowPassLength(false);
                        setShowPassMatch(false);
                        setShowCodeException(false);
                        setShowLimitExceededException(false);
                        setShowInvalidParameterException(false);
                        setShowUserNotFoundException(true);
                        setShowDefaultError(false);
                        setShowExpiredCodeException(false);
                        setShowPassChanged(false);
                        break;
                    case "InvalidParameterException":
                        setShowUserNotFound(false);
                        setShowCodeSent(false);
                        setShowEnterCode(false);
                        setShowInvalidCode(false);
                        setSetShowPassLength(false);
                        setShowPassMatch(false);
                        setShowCodeException(false);
                        setShowLimitExceededException(false);
                        setShowInvalidParameterException(true);
                        setShowUserNotFoundException(false);
                        setShowDefaultError(false);
                        setShowExpiredCodeException(false);
                        setShowPassChanged(false);
                        break;
                    case "CodeMismatchException":
                        setShowUserNotFound(false);
                        setShowCodeSent(false);
                        setShowEnterCode(false);
                        setShowInvalidCode(false);
                        setSetShowPassLength(false);
                        setShowPassMatch(true);
                        setShowCodeException(false);
                        setShowLimitExceededException(false);
                        setShowInvalidParameterException(false);
                        setShowUserNotFoundException(false);
                        setShowDefaultError(false);
                        setShowExpiredCodeException(false);
                        setShowPassChanged(false);
                        break;
                    default:
                        setShowUserNotFound(false);
                        setShowCodeSent(false);
                        setShowEnterCode(false);
                        setShowInvalidCode(false);
                        setSetShowPassLength(false);
                        setShowPassMatch(false);
                        setShowCodeException(false);
                        setShowLimitExceededException(false);
                        setShowInvalidParameterException(false);
                        setShowUserNotFoundException(false);
                        setShowDefaultError(true);
                        setShowExpiredCodeException(false);
                        setShowPassChanged(false);
                        break;
                }
            }
        }
    }

    async function signIn(prov) {
        switch (prov) {
            case "google":
                await (await Auth.federatedSignIn({ provider: "Google" }))
                    .catch((err) => {
                        console.error(err);
                    });
                break;
            default:
                try {
                    if (userEmail === " " || userEmail === "" || userEmail === null || userEmail === undefined) {
                        setShowMsgEmail(true);
                        setShowMsgPass(false);
                        setShowInvalidUser(false);

                    } else if (userPass === " " || userPass === "" || userPass === null || userPass === undefined) {
                        setShowMsgEmail(false);
                        setShowMsgPass(true);
                        setShowInvalidUser(false);
                    } else {

                        const user = await Auth.signIn(userEmail, userPass);
                        setCurrentUser(user);
                        if (user.challengeName === "SMS_MFA" ||
              user.challengeName === "SOFTWARE_TOKEN_MFA") {
                            console.log("You need to get the code from the UI inputs");
                            // You need to get the code from the UI inputs
                            // and then trigger the following function with a button click
                            const code = ""/* getCodeFromUserInput() */;
                            // If MFA is enabled, sign-in should be confirmed with the confirmation code
                            Auth.confirmSignIn(
                                user,   // Return object from Auth.signIn()
                                code,   // Confirmation code
                                user.challengeName === "SMS_MFA" ? "SMS_MFA" : "SOFTWARE_TOKEN_MFA"// MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
                            );
                        } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                            setShowPassForNewUser(true);
                            setShowLogin(false);

                        } else if (user.challengeName === "MFA_SETUP") {
                            // This happens when the MFA method is TOTP
                            // The user needs to setup the TOTP before using it
                            // More info please check the Enabling MFA part

                            //Auth.setupTOTP(user);
                        }
                        console.log("Correct user");
                    }
                } catch (err) {
                    console.error(err);
                    if (err != null && err.code != null) {
                        switch (err.code) {
                            case "ExpiredCodeException":
                                await Auth.forgotPassword(userEmail);
                                setShowUserNotFound(false);
                                setShowCodeSent(false);
                                setShowEnterCode(false);
                                setShowInvalidCode(false);
                                setSetShowPassLength(false);
                                setShowPassMatch(false);
                                setShowCodeException(false);
                                setShowLimitExceededException(false);
                                setShowInvalidParameterException(false);
                                setShowUserNotFoundException(false);
                                setShowDefaultError(false);
                                setShowExpiredCodeException(true);
                                setShowPassChanged(false);
                                break;
                            case "LimitExceededException":
                                setShowUserNotFound(false);
                                setShowCodeSent(false);
                                setShowEnterCode(false);
                                setShowInvalidCode(false);
                                setSetShowPassLength(false);
                                setShowPassMatch(false);
                                setShowCodeException(false);
                                setShowLimitExceededException(true);
                                setShowInvalidParameterException(false);
                                setShowUserNotFoundException(false);
                                setShowDefaultError(false);
                                setShowExpiredCodeException(false);
                                setShowPassChanged(false);
                                break;
                            case "UserNotFoundException":
                                setShowUserNotFound(false);
                                setShowCodeSent(false);
                                setShowEnterCode(false);
                                setShowInvalidCode(false);
                                setSetShowPassLength(false);
                                setShowPassMatch(false);
                                setShowCodeException(false);
                                setShowLimitExceededException(false);
                                setShowInvalidParameterException(false);
                                setShowUserNotFoundException(true);
                                setShowDefaultError(false);
                                setShowExpiredCodeException(false);
                                setShowPassChanged(false);
                                break;
                            case "InvalidParameterException":
                                setShowUserNotFound(false);
                                setShowCodeSent(false);
                                setShowEnterCode(false);
                                setShowInvalidCode(false);
                                setSetShowPassLength(false);
                                setShowPassMatch(false);
                                setShowCodeException(false);
                                setShowLimitExceededException(false);
                                setShowInvalidParameterException(true);
                                setShowUserNotFoundException(false);
                                setShowDefaultError(false);
                                setShowExpiredCodeException(false);
                                setShowPassChanged(false);
                                break;
                            case "CodeMismatchException":
                                setShowUserNotFound(false);
                                setShowCodeSent(false);
                                setShowEnterCode(false);
                                setShowInvalidCode(false);
                                setSetShowPassLength(false);
                                setShowPassMatch(false);
                                setShowCodeException(false);
                                setShowLimitExceededException(false);
                                setShowInvalidParameterException(true);
                                setShowUserNotFoundException(false);
                                setShowDefaultError(false);
                                setShowExpiredCodeException(false);
                                setShowPassChanged(false);
                                break;
                            case "NotAuthorizedException":
                                setShowMsgEmail(false);
                                setShowMsgPass(false);
                                setShowInvalidUser(true);
                                setShowPassChanged(false);
                                break;
                            default:
                                setShowUserNotFound(false);
                                setShowCodeSent(false);
                                setShowEnterCode(false);
                                setShowInvalidCode(false);
                                setSetShowPassLength(false);
                                setShowPassMatch(false);
                                setShowCodeException(false);
                                setShowLimitExceededException(false);
                                setShowInvalidParameterException(false);
                                setShowUserNotFoundException(false);
                                setShowDefaultError(true);
                                setShowExpiredCodeException(false);
                                setShowPassChanged(false);
                                break;
                        }
                    }
                }
                break;
        }
    }

    async function forgotSubmit() {
        if (code === null || code === "" || code === " " || code === undefined || code === 0) {
            setShowUserNotFound(false);
            setShowCodeSent(false);
            setShowInvalidCode(false);
            setSetShowPassLength(false);
            setShowCodeException(false);
            setShowEnterCode(true);
            setShowPassChanged(false);
            return;
        }
        if (code !== null && code !== "" && code.length > 0 && isNaN(code)) {
            setShowUserNotFound(false);
            setShowCodeSent(false);
            setShowEnterCode(false);
            setSetShowPassLength(false);
            setShowCodeException(false);
            setShowInvalidCode(true);
            setShowPassChanged(false);
            return;
        }
        if (newPass === null || newPass.length < 8) {
            setShowUserNotFound(false);
            setShowCodeSent(false);
            setShowEnterCode(false);
            setShowInvalidCode(false);
            setShowCodeException(false);
            setSetShowPassLength(true);
            setShowPassChanged(false);
            return;
        }
        if (newPass !== rePass) {
            setShowPassChanged(false);
            setShowUserNotFound(false);
            setShowCodeSent(false);
            setShowEnterCode(false);
            setShowInvalidCode(false);
            setSetShowPassLength(false);
            setShowCodeException(false);
            setShowPassMatch(true);
            return;
        }

        try {
            await Auth.forgotPasswordSubmit(userEmail, code, newPass);
            setShowLoginForgot(false);
            setShowLogin(true);

            setShowUserNotFound(false);
            setShowCodeSent(false);
            setShowEnterCode(false);
            setShowInvalidCode(false);
            setSetShowPassLength(false);
            setShowPassMatch(false);
            setShowCodeException(false);
            setShowLimitExceededException(false);
            setShowInvalidParameterException(false);
            setShowUserNotFoundException(false);
            setShowDefaultError(false);
            setShowExpiredCodeException(false);
            setShowPassChanged(true);

        } catch (err) {
            console.error(err);
            if (err != null && err.code != null) {
                switch (err.code) {
                    case "ExpiredCodeException":
                        await Auth.forgotPassword(userEmail);
                        setShowUserNotFound(false);
                        setShowCodeSent(false);
                        setShowEnterCode(false);
                        setShowInvalidCode(false);
                        setSetShowPassLength(false);
                        setShowPassMatch(false);
                        setShowCodeException(false);
                        setShowLimitExceededException(false);
                        setShowInvalidParameterException(false);
                        setShowUserNotFoundException(false);
                        setShowDefaultError(false);
                        setShowExpiredCodeException(true);
                        setShowPassChanged(false);
                        break;
                    case "LimitExceededException":
                        setShowUserNotFound(false);
                        setShowCodeSent(false);
                        setShowEnterCode(false);
                        setShowInvalidCode(false);
                        setSetShowPassLength(false);
                        setShowPassMatch(false);
                        setShowCodeException(false);
                        setShowExpiredCodeException(false);
                        setShowInvalidParameterException(false);
                        setShowUserNotFoundException(false);
                        setShowDefaultError(false);
                        setShowLimitExceededException(true);
                        setShowPassChanged(false);
                        break;
                    case "UserNotFoundException":
                        setShowUserNotFound(false);
                        setShowCodeSent(false);
                        setShowEnterCode(false);
                        setShowInvalidCode(false);
                        setSetShowPassLength(false);
                        setShowPassMatch(false);
                        setShowCodeException(false);
                        setShowExpiredCodeException(false);
                        setShowInvalidParameterException(false);
                        setShowLimitExceededException(false);
                        setShowDefaultError(false);
                        setShowUserNotFoundException(true);
                        setShowPassChanged(false);
                        break;
                    case "InvalidParameterException":
                        setShowUserNotFound(false);
                        setShowCodeSent(false);
                        setShowEnterCode(false);
                        setShowInvalidCode(false);
                        setSetShowPassLength(false);
                        setShowPassMatch(false);
                        setShowCodeException(false);
                        setShowExpiredCodeException(false);
                        setShowLimitExceededException(false);
                        setShowUserNotFoundException(false);
                        setShowDefaultError(false);
                        setShowInvalidParameterException(true);
                        setShowPassChanged(false);
                        break;
                    case "CodeMismatchException":
                        setShowUserNotFound(false);
                        setShowCodeSent(false);
                        setShowEnterCode(false);
                        setShowInvalidCode(false);
                        setSetShowPassLength(false);
                        setShowPassMatch(false);
                        setShowExpiredCodeException(false);
                        setShowLimitExceededException(false);
                        setShowUserNotFoundException(false);
                        setShowInvalidParameterException(false);
                        setShowDefaultError(false);
                        setShowCodeException(true);
                        setShowPassChanged(false);
                        break;
                    default:
                        setShowUserNotFound(false);
                        setShowCodeSent(false);
                        setShowEnterCode(false);
                        setShowInvalidCode(false);
                        setSetShowPassLength(false);
                        setShowPassMatch(false);
                        setShowExpiredCodeException(false);
                        setShowLimitExceededException(false);
                        setShowUserNotFoundException(false);
                        setShowInvalidParameterException(false);
                        setShowCodeException(false);
                        setShowDefaultError(true);
                        setShowPassChanged(false);
                        //SerializationException
                        break;
                }
            }
        }
    }

    function changeLang() {
        if (t("login.lang") === "English") {
            setTimeout(() => {
                i18n.changeLanguage("en");
            }, 0);
        } else {
            setTimeout(() => {
                i18n.changeLanguage("es");
            }, 0);
        }
    }

    function handleKeyDown(key) {
        if (key === "Enter") {
            signIn();
        }
    }

    const [lng] = getBaseLangs(i18n);
    return (
        <>
            <Suspense fallback={<div> <img src={Loading} alt="Loading..." /></div>}>
                <div id='container-login'>
                    <div id='main'>
                        <div className='nav-wrapper' id='utilLang'>
                            <nav>
                                <ul className='nav'>
                                    <li className='langSelector' style={{ cursor: "pointer" }}>
                                        <a onClick={changeLang}><img alt="change-language" src={mundo} />{t("login.lang")}</a>
                                    </li>
                                    <li>
                                        <a href="//www.wiseconn.com/contact" target="_blank" rel="noreferrer">{t("login.contacto")}</a>
                                    </li>
                                    <li>
                                        <a href="//www.wiseconn.com" target="_blank" rel="noreferrer">Wiseconn</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className='contenido-wrapper'>
                            <img src={logo_dc} alt="DROPCONTROL" className="img-responsive-principal" />

                            {showLogin &&
                <div className='loginForm' onKeyDown={(e) => handleKeyDown(e.key)}>
                    <div className='inputWIcon'>
                        <span className='inputIcon'><FontAwesomeIcon icon={faUser} /></span>

                        <input className='inputLogin' name='usuario' id='usuario' type='email' tabIndex='1'
                            value={userEmail} placeholder={t("login.pHolderUser")}
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                        />
                    </div>

                    <div className='inputWIcon'>
                        <span className='inputIcon'><FontAwesomeIcon icon={faLock} /></span>

                        <input className='inputLogin' name='contraseña' id='contraseña' type='password' tabIndex='2'
                            placeholder={t("login.pHolderPass")}
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                        />
                    </div>

                    <a className="btn submit" onClick={signIn}>{t("login.btnLogin")}</a>

                    <div id='loginForm_validation' className='errores'>
                        { }
                        {showInvalidUser ? t("login.NotAuthorizedException") : null}
                        {showMsgEmail ? t("login.enterMsgEmail") : null}
                        {showMsgPass ? t("login.enterMsgPass") : null}
                        {showCodeException ? t("login.CodeMismatchException") : null}
                        {showExpiredCodeException ? t("login.ExpiredCodeException") : null}
                        {showLimitExceededException ? t("login.LimitExceededException") : null}
                        {showInvalidParameterException ? t("login.InvalidParameterException") : null}
                        {showUserNotFoundException ? t("login.UserNotFoundException") : null}
                        {showDefaultError ? t("login.defaultError") : null}
                        {showPassChanged ? t("login.msgPassChanged") : null}
                    </div>

                    <div className="text-center">
                        <p>
                            <a className="content-link" onClick={showForgot}>{t("login.forgotPw")}</a>
                        </p>
                    </div>

                    <fieldset className="line">
                        <legend className="ng-scope">{t("login.orTxt")}</legend>
                    </fieldset>

                    <a className="btn submit Google" onClick={() => signIn("google")} aria-label="Google" />
                </div>}

                            {showLoginForgot &&
                <div className='loginForm'>
                    <div className="infoMessage">
                        {msgInfoForgot}
                    </div>

                    <div className='inputWIcon'>
                        <span className='inputIcon'><FontAwesomeIcon icon={faUser} /></span>
                        <input className='inputLogin' name='usuario' id='usuario' type='email' tabIndex='1'
                            placeholder='User' value={userEmail}
                            onChange={(e) => handleChange(e.target.name, e.target.value)} disabled
                        />
                    </div>

                    <div className='inputWIcon'>
                        <span className='inputIcon'><FontAwesomeIcon icon={faLock} /></span>
                        <input className='inputLogin' name='code' id='code' type='text' tabIndex='2'
                            placeholder={pHolderCode}
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                        />
                    </div>

                    <div className='inputWIcon'>
                        <span className='inputIcon'><FontAwesomeIcon icon={faLock} /></span>
                        <input className='inputLogin' name='newpassword' id='newpassword' type='password' tabIndex='3'
                            placeholder={pHolderPass}
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                        />
                    </div>

                    <div className='inputWIcon'>
                        <span className='inputIcon'><FontAwesomeIcon icon={faLock} /></span>
                        <input className='inputLogin' name='repassword' id='repassword' type='password' tabIndex='4'
                            placeholder={pHolderPassConfirm}
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                        />
                    </div>

                    <a className='btn submit' onClick={forgotSubmit}>{changePassword}</a>

                    <div id='loginForm_validation' className='errores'>
                        {showUserNotFound ? t("login.UserNotFoundException") : null}
                        {showCodeSent ? t("login.msgForgotCodeSent") : null}
                        {showEnterCode ? t("login.enterMsgCode") : null}
                        {showInvalidCode ? t("login.msgInvalidCode") : null}
                        {showPassLength ? t("login.msgPassLength") : null}
                        {showPassMatch ? t("login.msgPassMatch") : null}
                        {showCodeException ? t("login.CodeMismatchException") : null}
                        {showExpiredCodeException ? t("login.ExpiredCodeException") : null}
                        {showLimitExceededException ? t("login.LimitExceededException") : null}
                        {showInvalidParameterException ? t("login.InvalidParameterException") : null}
                        {showUserNotFoundException ? t("login.UserNotFoundException") : null}
                        {showDefaultError ? t("login.defaultError") : null}
                    </div>
                </div>}

                            {showPassForNewUser &&
                <>
                    <div className='loginForm'>
                        <div className='inputWIcon'>
                            <span className='inputIcon'><FontAwesomeIcon icon={faUser} /></span>
                            <input className='inputLogin' name='usuario' id='usuario' type='email' tabIndex='1'
                                placeholder='User' value={userEmail}
                                onChange={(e) => handleChange(e.target.name, e.target.value)} disabled
                            />
                        </div>

                        <div className='inputWIcon'>
                            <span className='inputIcon'><FontAwesomeIcon icon={faLock} /></span>
                            <input className='inputLogin' name='newpassword' id='newpassword' type='password' tabIndex='3'
                                placeholder={pHolderPass}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                            />
                        </div>

                        <div className='inputWIcon'>
                            <span className='inputIcon'><FontAwesomeIcon icon={faLock} /></span>
                            <input className='inputLogin' name='repassword' id='repassword' type='password' tabIndex='4'
                                placeholder={pHolderPassConfirm}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                            />
                        </div>
                        <div id='loginForm_validation' className='errores'>
                            {showPassLength ? t("login.msgPassLength") : null}
                            {showPassMatch ? t("login.msgPassMatch") : null}
                            {showDefaultError ? t("login.defaultError") : null}
                        </div>

                        <a className="btn submit" onClick={resetSubmit}>{changePassword}</a>
                    </div>
                </>}
                        </div>
                    </div>
                </div>
                {lng === "es" ? (
                    <footer id="footer">
                        <div className="text-center">
                            <p className="text-footer">
                Ingeniería Wiseconn SpA.
                ·
                                <span>Soporte <a href="tel:+56226565003"> (+56 2) 26565003 </a></span>
                ·
                                <span>Carmencita 25, Oficina 52, Las Condes - Chile - <a href="tel:+56229078586">(+56 2) 29078586</a></span>
                                <a href="https://www.dropcontrol.com/public/privacy.jsp?hl=es" className="footer-link"> Políticas de Privacidad </a>
                ·
                                <a href="https://www.dropcontrol.com/public/terms.jsp?hl=es" className="footer-link">Condiciones de Uso</a>
                            </p>
                        </div>
                    </footer>
                ) : (
                    <footer id="footer">
                        <div className="text-center">
                            <p className="text-footer">
                WiseConn Engineering Inc.
                ·
                                <span>Support <a href="tel:+15593440800">+1 (559) 344-0800</a></span>
                ·
                                <span>5252 E Home Ave, Fresno, CA 93722 - USA - <a href="tel:+15593267613">+1 (559) 326-7613</a></span>
                                <a href="https://www.dropcontrol.com/public/privacy.jsp?hl=en" className="footer-link"> Privacy Policy</a>
                ·
                                <a href="https://www.dropcontrol.com/public/terms.jsp?hl=en" className="footer-link">Terms of Use</a>
                            </p>
                        </div>
                    </footer>
                )}
            </Suspense>
        </>
    );
}

export default Login;
