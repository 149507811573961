import { Component, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import { RestAPI } from "@aws-amplify/api-rest";
import { v1 as uuidv1 } from "uuid";
import { getCache } from "../../utils/logcache";

const ShowError = ({ uuid, resetOnLocationChange, clearState, time }) => {
    const { t } = useTranslation();
    const clearStateRef = useRef(clearState);
    if (clearStateRef.current !== clearState) clearStateRef.current = clearState;
    const location = useLocation();
    const lastLocation = useRef();
    const [showReloadMsg, setShowReloadMsg] = useState(true);

    useEffect(() => {
        if (resetOnLocationChange && lastLocation.current && lastLocation.current !== location) {
            clearStateRef.current && clearStateRef.current();
        }
        lastLocation.current = lastLocation;
    }, [resetOnLocationChange, location]);

    useEffect(() => {
        const last_error_time = localStorage.getItem("last_error_time") ? new Date(localStorage.getItem("last_error_time")) : null;
        let timer;
        if (last_error_time) {
            if ((time.getTime() - last_error_time.getTime()) > 60000) {
                !showReloadMsg && setShowReloadMsg(true);
                let timeleft = 10;
                timer = setInterval(() => {
                    const el = document.getElementById("reload");
                    if (!el) {
                        clearInterval(timer);
                        return;
                    }
                    if (timeleft <= 0) {
                        if (el) el.innerHTML = t("error.reloadMsg") + timeleft + "s";
                        clearInterval(timer);
                        localStorage.setItem("last_error_time", time);
                        window.location.reload();
                    } else {
                        if (el) el.innerHTML = t("error.reloadMsg") + timeleft + "s";
                    }
                    timeleft--;
                }, 1000);
            } else {
                setShowReloadMsg(false);
            }
        } else {
            !showReloadMsg && setShowReloadMsg(true);
            let timeleft = 10;
            timer = setInterval(() => {
                const el = document.getElementById("reload");
                if (!el) {
                    clearInterval(timer);
                    return;
                }
                if (timeleft <= 0) {
                    if (el) el.innerHTML = t("error.reloadMsg") + timeleft + "s";
                    clearInterval(timer);
                    localStorage.setItem("last_error_time", time);
                    window.location.reload();
                } else {
                    if (el) el.innerHTML = t("error.reloadMsg") + timeleft + "s";
                }
                timeleft--;
            }, 1000);
        }

    //return () => timer && clearInterval(timer);
    }, []);

    return (
        <div>
            <h2>{t("error.msg")}</h2>
            <p>{t("error.id")}: {uuid}</p>
            {showReloadMsg &&
        <>
            <br />
            <p id="reload" />
        </>}
        </div>
    );
};

class AWSErrorBoundary extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = { error: null, uuid: null };
    }

    componentDidMount() { this._isMounted = true; }
    componentWillUnmount() { this._isMounted = false; }
    clearState() {
        if (this && this._isMounted) this.setState({ error: null });
    }

    static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
        const uuid = uuidv1();
        const time = new Date();
        error.cache = getCache();
        if (error) error.uuid = uuid;
        return { error: error && error.toString(), uuid, time };
    }

    componentDidCatch(error, errorInfo) {
    // Log error messages to an error reporting service
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
        const his = window.history;
        const loc = window.location;
        const { expanded, updateTree } = his.state && his.state.state || {};
        const state = JSON.stringify({ expanded, updateTree }).substring(0, 2000);
        const url = loc.href;
        const boundaryName = this.props.name;
        const uuid = error.uuid;
        const stack = error.stack && error.stack.substring(0, 2000);
        const errorMsg = error.toString();
        const componentStack = errorInfo.componentStack && errorInfo.componentStack.substring(0, 2000);
        const cache = Array.isArray(error.cache) ? error.cache.sort((a, b) => {
            if (!a) return -1;
            if (!b) return 1;
            if (a.ts > b.ts) return 1;
            if (a.ts < b.ts) return -1;
            return 0;
        }) : undefined;
        const cacheString = cache && cache.map(a => JSON.stringify(a)).join("\n");

        const data = { uuid, errorMsg, url, state, boundaryName, componentStack, stack, cache: cacheString };
        RestAPI.post("apiAdminDropControl", "/notify/error", { body: data }).catch(e => console.error(e));
    }

    render() {
        if (this.state.error) {
            return <ShowError resetOnLocationChange={this.props.resetOnChange} clearState={this.clearState.bind(this)} uuid={this.state.uuid} time={this.state.time} />;
        }
        // Normally, just render children
        return this.props.children;
    }
}

export default AWSErrorBoundary;
