import { Dialog } from "primereact/dialog";
import Loading from "../../img/loading-borde-suave.gif";
import { useEffect, useState } from "react";

const LoadingDialog = ({ appendTo, targetId, extraClasses, hide }) => {
    const [target, setTarget] = useState(null);

    useEffect(() => {
        if (targetId) {
            const el = document.getElementById(targetId);
            setTarget(el ?? null);
        }
    }, []);

    const finalTarget = appendTo ?? target;
    const providesTarget = Boolean(targetId);
    if (providesTarget && !finalTarget) return null;

    return (
        <Dialog modal visible={!hide}
            className={`loadingModal ${extraClasses || ""}`}
            closeOnEscape={false}
            pt={{
                mask: { style: { position: "absolute" } }
            }}
            content={
                <img className="img-loading" src={Loading} alt="Loading..." />
            }
            appendTo={finalTarget}
        />
    );
};

export default LoadingDialog;
