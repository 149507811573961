const getBaseLangs = (i18n) => {
    const languages = i18n.languages;
    const n = languages.length;
    if (n < 2) {
        return [languages[0], languages[0]];
    }
    return [languages[n-2], languages[n-1]];
};

export default getBaseLangs;
