import withName from "../../hoc/withName";
import { InputText } from "primereact/inputtext";


const InputTextPrime = ({ value = null,setValue=null,onChange=null,piIcon=null, piIconPos="right", ...inputTextProps }) => {
    if(piIcon) return(
        <span className={`p-input-icon-${piIconPos}`}>
            <i className={`pi ${piIcon}`} />
            <InputText value={value} onChange={onChange?onChange:(e) => setValue(e.target.value)} {...inputTextProps} />
        </span>
    );
    return (
        <>
            <InputText className="input-text-prime" value={value} onChange={onChange?onChange:(e) => setValue(e.target.value)} {...inputTextProps} />
        </>
    );
};

export default withName(InputTextPrime, "InputTextPrime");
