import { createContext, useCallback, useRef, useState } from "react";
import { RestAPI } from "@aws-amplify/api-rest";

export const TypesContext = createContext([]);

const TypesProvider = ({ children }) => {
    const [typesState, setTypesState] = useState({});
    const loadedState = useRef({});

    const dispatch = useCallback((action) => {
        switch (action.type) {
            case "get": {
                if (!action.lang) action.lang = "en";
                if (loadedState.current[action.lang] &&
            loadedState.current[action.lang][action.path]) {
                    return;
                }
                if (!loadedState.current[action.lang]) loadedState.current[action.lang] = {};
                loadedState.current[action.lang][action.path] = true;

                RestAPI.get("apiAdminDropControl", `/farms/${action.path}/${action.lang}`)
                    .then(res => JSON.parse(JSON.stringify(res)))
                    .then(json => setTypesState((state) => {
                        if (!state[action.lang]) state[action.lang] = {};
                        state[action.lang][action.path] = json;
                        return { ...state };
                    }))
                    .catch(err => console.error(err));
                return;
            }
            default: return;
        }
    }, []);

    return (
    // this will cause a re-render of all components that use this context on each context re-render,
    // because the array is newly-created each time
    // eslint-disable-next-line react/jsx-no-constructed-context-values
        <TypesContext.Provider value={[typesState, dispatch]}>
            {children}
        </TypesContext.Provider>
    );
};

export default TypesProvider;
