

import withName from "../../hoc/withName";
import { Chip } from "primereact/chip";

const ChipPrime = ({ label=null, removable =true,removeFunction=null, ...chipProps }) => {
    const content = (data) => {
        return (
            <>
                <span className="chip-prime-avatar">{data?.label?.charAt(0)?.toUpperCase()}</span>
                <span className="chip-prime-content">{data?.label}</span>
                {removable? <i onClick={removeFunction} className="chip-prime-removeIcon chip-prime-content pi pi-times" />:null}
            </>
        );
    };
    return (
        <>
            <Chip className="chip-prime" label={label} removable={removable} template={content} {...chipProps} />
        </>
    );
};

export default withName(ChipPrime, "ChipPrime");
