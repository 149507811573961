// From https://github.com/Tintef/react-google-places-autocomplete/blob/master/src/helpers/injectScript.ts
const constants = {
    INJECTION_STATE_NOT_YET: "NOT_YET",
    INJECTION_STATE_IN_PROGRESS: "IN_PROGRESS",
    INJECTION_STATE_DONE: "DONE"
};

let injectionState = constants.INJECTION_STATE_NOT_YET;
let injectionError = null;

let onScriptLoadCallbacks = [];
let onScriptLoadErrorCallbacks = [];

// Returns a promise that resolves
//   - when the script becomes available or
//   - immediately, if the script had already been injected due to a prior call.
//
// The promise is rejected in case the injection fails (e.g. due to a network
// error).
//
// Note that only the first call of the function will actually trigger an
// injection with the provided API key, the subsequent calls will be
// resolved/rejected when the first one succeeds/fails.
const injectScript = (apiKey, lang) => {
    switch (injectionState) {
        case constants.INJECTION_STATE_DONE:
            return injectionError ? Promise.reject(injectionError) : Promise.resolve();

        case constants.INJECTION_STATE_IN_PROGRESS:
            return new Promise((resolve, reject) => {
                onScriptLoadCallbacks.push(resolve);
                onScriptLoadErrorCallbacks.push(reject);
            });

        default: // INJECTION_STATE_NOT_YET
            injectionState = constants.INJECTION_STATE_IN_PROGRESS;

            return new Promise((resolve, reject) => {
                const scriptAlreadyExists = document.querySelector("script#maps-places") !== null;
                if (scriptAlreadyExists) {
                    // Resolve current promise
                    resolve();
                    // Resolve the pending promises in their respective order
                    onScriptLoadCallbacks.forEach((cb) => cb());
                    // Cleanup
                    onScriptLoadCallbacks = [];
                    onScriptLoadErrorCallbacks = [];
                    injectionState = constants.INJECTION_STATE_DONE;
                }

                const script = document.createElement("script");
                const libs = "drawing,places,geometry";
                script.type = "text/javascript";
                script.src = `https://maps.googleapis.com/maps/api/js?v=quarterly&key=${apiKey}&language=${lang}&libraries=${libs}`;
                script.async = true;
                script.defer = true;
                script.id = "maps-places";

                // Release callbacks and unregister listeners
                const cleanup = () => {
                    script.removeEventListener("load", onScriptLoad);
                    script.removeEventListener("error", onScriptLoadError);
                    onScriptLoadCallbacks = [];
                    onScriptLoadErrorCallbacks = [];
                    injectionState = constants.INJECTION_STATE_DONE;
                };

                const onScriptLoad = () => {
                    // Resolve current promise
                    resolve();
                    // Resolve the pending promises in their respective order
                    onScriptLoadCallbacks.forEach((cb) => cb());

                    cleanup();
                };
                const onScriptLoadError = () => {
                    // Reject all promises with this error
                    injectionError = new Error("[react-google-places-autocomplete] Could not inject Google script");
                    // Reject current promise with the error
                    reject(injectionError);
                    // Reject all pending promises in their respective order with the error
                    onScriptLoadErrorCallbacks.forEach((cb) => cb(injectionError));

                    cleanup();
                };
                script.addEventListener("load", onScriptLoad);
                script.addEventListener("error", onScriptLoadError);

                document.body.appendChild(script);
            });
    }
};

export default injectScript;
