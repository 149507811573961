import { useEffect, useState, useContext } from "react";
import TagManager from "react-gtm-module";
import { useLocation } from "react-router";

import { AuthenticatorContext } from "../../contexts/AuthenticatorContext";
import { FarmContext } from "../../contexts/FarmContext";

const getFarm = (location) => {
    const params = new URLSearchParams(location && location.search);
    const farm = params.get("farm") || params.get("farmblack");
    return parseInt(farm, 10);
};

const GATracker = ({ history }) => {
    const location = useLocation();
    const [historyChange, setHistoryChange] = useState(null);

    const { userInfo } = useContext(AuthenticatorContext);
    const [ds] = useContext(FarmContext);
    const dataFarm = ds?.dataFarm ?? null;

    // TODO: https://developer.mozilla.org/en-US/docs/Web/API/Navigation_API
    // or https://github.com/remix-run/react-router/discussions/9565
    /*
    useEffect(() => {
        setHistoryChange((state) => {
            const prev = state ? state.current : (document.referrer.length > 0 ? document.referrer : null);

            return { prev, current: window.location.href };
        });
    }, [history.location]);
    */
    useEffect(() => {
        setHistoryChange((state) => {
            const prev = state ? state.current : (document.referrer.length > 0 ? document.referrer : null);

            return { prev, current: window.location.href };
        });
    }, [location]);

    useEffect(() => {
        if (dataFarm) {
            TagManager.dataLayer({
                dataLayer: {
                    event: "campo_visitado",
                    campo_nombre: dataFarm.nombre,
                    campo_id: dataFarm.campo_id,
                    user_email: userInfo.email,
                    user_profile: userInfo.profile,
                    user_es_sistema: userInfo.es_sistema,
                    user_type: userInfo.es_sistema == "true" ? "Super Usuario" : dataFarm.es_cliente ? "Cliente" : "No Cliente"
                }
            });
        }
    }, [dataFarm]);

    useEffect(() => {
        if (historyChange) {
            const campoId = getFarm(history.location);
            const page_referrer = historyChange.prev;
            const page_location = historyChange.current;

            if (!isNaN(campoId) && dataFarm && dataFarm.campo_id === campoId) {
                TagManager.dataLayer({
                    dataLayer: {
                        event: "page_view",
                        page_path: history.location.pathname,
                        page_search: history.location.pathname + history.location.search,
                        page_location,
                        page_referrer,
                        user_email: userInfo.email,
                        user_profile: userInfo.profile,
                        user_es_sistema: userInfo.es_sistema,
                        user_type: userInfo.es_sistema == "true" ? "Super Usuario" : dataFarm.es_cliente ? "Cliente" : "No Cliente"
                    }
                });
            } else if (isNaN(campoId)) {
                TagManager.dataLayer({
                    dataLayer: {
                        event: "page_view",
                        page_path: history.location.pathname,
                        page_search: history.location.pathname + history.location.search,
                        page_location,
                        page_referrer,
                        user_email: userInfo.email,
                        user_profile: userInfo.profile,
                        user_es_sistema: userInfo.es_sistema,
                        user_type: null
                    }
                });
            }
        }
    }, [dataFarm, userInfo, historyChange]);

    return null;
};

export default GATracker;
