import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { locale, addLocale } from "primereact/api";
import primelocales from "./primelocale.json";
const formatters = {};

const loaded = {};
const getCLDR = (languages, callback) => {
    const promise = Promise.all(languages.map(async (lng) => {
        if (lng !== "es" && lng !== "it" && lng !== "tr" && lng !== "fr" /*&& lng !== "es-MX"*/) return;
        if (!loaded[lng]) {
            /*const { numbers, calendar, timeZoneNames } = await import(`./i18n_${lng}.js`);*/
            loaded[lng] = true;
        }
    })).catch(console.error);

    return (err, ret) => promise.then(() => callback(err, ret));
};

/* Use i18next-http-backend, while also loading Cldr data */
class CustomBackend extends Backend {
    constructor(services, options, allOptions) {
        super(services, options, allOptions);
    }

    readMulti(languages, namespaces, callback) {
        const promiseCallback = getCLDR(languages, callback);
        super.readMulti(languages, namespaces, promiseCallback);
    }

    read(language, namespace, callback) {
        const promiseCallback = getCLDR([language], callback);
        super.read(language, namespace, promiseCallback);
    }
}

const onChange = {
    type: "3rdParty",

    init(i18n) {
        i18n.on("languageChanged", (lng) => {
            formatters.number = Intl.NumberFormat(undefined, { useGrouping: true, maximumFractionDigits: 4 });
            document.documentElement.setAttribute("lang", lng);

            const params = {};
            params[lng] = {
                daterangepicker: {
                    applyText: i18n.t("forms.apply"),
                    cancelText: i18n.t("forms.cancel"),
                    days: i18n.t("forms.dias"),
                    endLabel: i18n.t("daterangepicker.endDate"),
                    startLabel: i18n.t("daterangepicker.startDate"),
                    customRange: i18n.t("daterangepicker.customRange"),
                    selectedDays: i18n.t("daterangepicker.selectedDays"),
                    placeholder: i18n.t("daterangepicker.placeholder")
                },
                grid: i18n.t("graficador.tabla_translation.grid", { returnObjects: true }),
                pager: i18n.t("graficador.tabla_translation.pager", { returnObjects: true }),
                "multi-select": i18n.t("multi-select", { returnObjects: true }),
            };
            addLocale(i18n.language, primelocales[i18n.language]);
            locale(i18n.language);
        });
    },
};

i18n
// load translation using http -> see /public/locales
// learn more: https://github.com/i18next/i18next-http-backend
    .use(CustomBackend)
// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
// we use a plugin for this to be sure it executes before react-i18next
    .use(onChange)
// pass the i18n instance to react-i18next.
    .use(initReactI18next)
// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        detection: {
            order: ["querystring", "cookie", "localStorage", "sessionStorage", "navigator"],
        },
        fallbackLng: (code) => {
            if (!code) return ["en"];
            const fallbacks = [code];

            // add base lang
            const baseLang = code.split("-")[0];
            if (baseLang !== code) fallbacks.push(baseLang);

            // last fallback to english
            if (baseLang !== "en") fallbacks.push("en");
            return fallbacks;
        },
        supportedLngs: ["en", "es", "it", "tr", "fr"/*, 'es-MX'*/],
        debug: false,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            format: (value, format) => {
                if (format === "number") return (value !== undefined && value !== null) ? formatters.number.format(value) : null;
                if (format === "number_unit") {
                    const { number, unit } = value;
                    return (number !== undefined && number !== null) ? `${formatters.number.format(number)} ${unit || ""}` : null;
                }
                return value;
            }
        }
    /* react: {
      useSuspense: false
    } */
    });

addLocale("es", {
    firstDayOfWeek: 1,
    showMonthAfterYear: true,
    dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
    monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
    monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
    today: "Hoy",
    clear: "Limpiar"
});
addLocale("it", {
    firstDayOfWeek: 1,
    showMonthAfterYear: true,
    dayNames: ["domenica", "lunedì", "martedì", "mercoledì", "giovedì", "venerdì", "sabato"],
    dayNamesShort: ["dom", "lun", "mar", "mer", "gio", "ven", "sab"],
    dayNamesMin: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
    monthNames: ["gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre"],
    monthNamesShort: ["gen", "feb", "mar", "apr", "mag", "giu", "lug", "ago", "set", "ott", "nov", "dic"],
    today: "Oggi",
    clear: "Pulisci"
});
addLocale("en", {
    firstDayOfWeek: 1,
    showMonthAfterYear: true,
    dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    today: "Today",
    clear: "Clear"
});
addLocale("fr", {
    firstDayOfWeek: 1,
    showMonthAfterYear: true,
    dayNames: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
    dayNamesShort: ["dim", "lun", "mar", "mer", "jeu", "ven", "sam"],
    dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
    monthNames: ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"],
    monthNamesShort: ["jan", "fév", "mar", "avr", "mai", "juin", "juil", "août", "sep", "oct", "nov", "déc"],
    today: "Aujourd'hui",
    clear: "Effacer"
});
addLocale("tr", {
    firstDayOfWeek: 1,
    showMonthAfterYear: true,
    dayNames: ["pazar", "pazartesi", "salı", "çarşamba", "perşembe", "cuma", "cumartesi"],
    dayNamesShort: ["paz", "pts", "sal", "çar", "per", "cum", "cmt"],
    dayNamesMin: ["P", "P", "S", "Ç", "P", "C", "C"],
    monthNames: ["ocak", "şubat", "mart", "nisan", "mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"],
    monthNamesShort: ["oca", "şub", "mar", "nis", "may", "Haz", "Tem", "Ağu", "Eyl", "Eki", "Kas", "Ara"],
    today: "Bugün",
    clear: "Temizle"
});

export default i18n;
