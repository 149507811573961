import { Auth } from "@aws-amplify/auth";
import { Amplify } from "@aws-amplify/core";
import wiseExports from "./wiseconn-exports.js";

wiseExports.aws_cloud_logic_custom.forEach((api) => {
    if (api.name === "apiAdminDropControl") {
        api.custom_header = async () => {
            return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
        };
    }
});

Amplify.configure(wiseExports);
