const globalCache = [];
const firstCache = [];
const secondCache = [];

const putLog = (log, prio) => {
    const ts = Date.now();
    if (prio === 1) {
        firstCache.push({ ts, log });
        if (firstCache.length > 1) firstCache.shift();
    } else if (prio === 2) {
        secondCache.push({ ts, log });
        if (secondCache.length > 3) secondCache.shift();
    } else {
        globalCache.push({ ts, log });
        if (globalCache.length > 12) globalCache.shift();
    }
    return null;
};
const getCache = () => globalCache.concat(firstCache, secondCache);

export { putLog, getCache };
