import { Suspense, useContext, useEffect, useMemo, useRef, useState } from "react";
import { RestAPI } from "@aws-amplify/api-rest";

import ErrorBoundary from "./components/Utils/ErrorBoundary";
import FarmProvider from "./contexts/FarmContext";
import TypesProvider from "./contexts/TranslatedTypes.js";
import withAuthenticator from "./hoc/withAuthenticator";
import { FarmContext } from "./contexts/FarmContext";
import Menubar from "./components/Menubar/Menubar";
import Header from "./components/Header/Header";
import Login from "./pages/Login/Login";
import PolicyAndTerms from "./pages/PolicyAndTerms/PolicyAndTerms";
import LoadingDialog from "../src/components/Utils/LoadingDialog";
import BillingMenubar from "./components/BillingMenuBar/BillingMenubar";
import { ToastContainer } from "react-toastify";
import "primeicons/primeicons.css";
import "./App.css";
import "./scss/styles.scss";
import "primereact/resources/themes/lara-light-blue/theme.css";
//import 'primeflex/primeflex.css'

import TagManager from "react-gtm-module";
import GATracker from "./components/GATracker/GATracker";

import { AuthenticatorContext } from "./contexts/AuthenticatorContext";

import { RouterProvider, UNSAFE_mapRouteProperties, Outlet, json, redirect, useMatch, useRouteLoaderData, useNavigation, useRevalidator } from "react-router";
import { createRouter, createBrowserHistory } from "@remix-run/router";
import { putLog } from "./utils/logcache";

const TAG_MANAGER_ID = import.meta.env.VITE_GTM_CONTAINER_ID;

//ReactGA.initialize(TRACKING_ID);

const tagManagerArgs = {
    gtmId: TAG_MANAGER_ID
};
TagManager.initialize(tagManagerArgs);

const dynamicRoutes = [
    {
        id: "general",
        parent: "with-tos",
        getChildren: () => import("./routes/general.js")
    },
    {
        id: "billing",
        parent: "with-tos",
        getChildren: () => import("./routes/billing.js")
    },
    {
        id: "farms",
        parent: "with-tos",
        getChildren: () => import("./routes/farms.js")
    },
];

/**
 *
 * @param {import("@remix-run/router").History} history
 * @param {import("@remix-run/router").AgnosticRouteObject[]} routes
 * @returns
 */
function createHistoryRouter(history, routes) {
    // for now, all route that could be added to the context are eternal. in the future, we should handle cleanups too
    const context = {};
    const addedDynamicRoutes = {};

    return createRouter({
        history, routes,
        future: {
            v7_fetcherPersist: true,
            v7_normalizeFormMethod: true,
            v7_partialHydration: true,
            v7_prependBasename: true,
            v7_relativeSplatPath: true
        },
        mapRouteProperties: UNSAFE_mapRouteProperties,
        // inner function copied from https://reactrouter.com/en/main/routers/create-browser-router#middleware
        async unstable_dataStrategy({
            matches
        }) {
            // Run middleware sequentially and let them add data to `context`
            let promError = null;
            let hasRedirect = false;
            const promises = []; // the other loaders will be started in the same sequence, but wont be waited for

            const isRedirectResult = (res) => (
                res.result?.status && (res.result.status >= 300 && res.result.status < 400)
            );
            const customHandlerCall = async (handler) => {
                // Whatever you pass to `handler` will be passed as the 2nd parameter
                // to your loader/action
                const result = await handler(context);
                return { type: "data", result };
            };
            const nullHandlerCall = async (handler) => {
                // This one will be used after we detect a redirect, so we dont get real data
                const result = await handler(context);
                return { type: "data", result };
            };

            for (const match of matches) {
                let prom;

                (prom = match.resolve(hasRedirect ? nullHandlerCall : customHandlerCall))
                    .then(res => {
                        if (isRedirectResult(res)) hasRedirect = true;
                    })
                    .catch(e => {
                        if (!promError) promError = e;
                    });
                promises.push(prom);

                if (hasRedirect) { // break early if we got a redirect
                    continue;
                }

                // if we have addToWiseContext, await for these loaders first
                if (match.route.handle?.wiseWait) {
                    // and add them to the context
                    const res = (await prom) ?? {};
                    if (!isRedirectResult(res) && match.route.handle?.addToWiseContext) {
                        if (!match.route.id) {
                            console.log("Invalid, you need a route id for this");
                            continue;
                        }

                        try {
                            if (match.shouldLoad) {
                                context[match.route.id] = await res.result?.clone?.()?.json?.();
                            }
                        } catch (error) {
                            console.log(error);
                        }
                    }
                }

                if (promError) throw promError;
            }

            // Run loaders in parallel with the `context` value
            return Promise.all(promises);
        },
        async unstable_patchRoutesOnMiss({ matches, patch }) {
            let leafRouteId = matches[matches.length - 1]?.route?.id;
            if (!leafRouteId) return;
            // hack: this doesnt handle very well the route with no added path
            if (leafRouteId === "root") leafRouteId = "with-tos";

            // maybe in the future we could add a route parameter to our dynamicRoutes, so we dont add all of the routes
            const childrenToPatch = dynamicRoutes.filter(cr => cr.parent === leafRouteId);
            if (!childrenToPatch.length) return;

            childrenToPatch.forEach(cr => {
                const uniqueId = `${cr.parent}-${cr.id}`;
                if (addedDynamicRoutes[uniqueId]) throw new Error("Route already added");
                addedDynamicRoutes[uniqueId] = true;
            });

            const childenInfo = (await Promise.all(childrenToPatch.map(cr => cr.getChildren()))).map(r => r.default);
            patch(leafRouteId, Array.prototype.concat.call(...childenInfo));
        }
    }).initialize();
}

const getRouter = () => {
    const personalInfoRef = { current: null };

    const history = createBrowserHistory({ window });
    const router = createHistoryRouter(history, [
        {
            path: "/",
            element: <Layout history={history} />,
            hydrateFallbackElement: <LoadingDialog />,
            // https://reactrouter.com/en/main/hooks/use-route-error
            errorElement: <LoadingDialog />,
            id: "root",
            handle: {
                wiseWait: true,
                addToWiseContext: true,
            },
            loader: async () => {
                const cache = personalInfoRef.current;
                delete personalInfoRef.current; // we are updating the info just because an action was called

                return json(cache ?? await RestAPI.get("apiAdminDropControl", "/personal"));
            },
            action: async ({ request }) => {
                // all we are doing in this action, is save the modified data on a cache, do the next loader execution will get it
                return json(personalInfoRef.current = await request.json());
            },
            shouldRevalidate: ({ formAction, formMethod, currentUrl, nextUrl }) => {
                if (currentUrl.pathname.includes("/tos") && nextUrl.pathname.includes("personal")) {
                    return true; // forced validate after ToS
                }
                return formAction === "/" && formMethod === "PATCH"; // this should only be revalidated on forced revalidations and the PATCH action
            },
            children: [
                {
                    path: "tos",
                    element: <PolicyAndTerms />,
                    loader: async (_, context) => {
                        if (context.root.politicas_aceptadas) {
                            return redirect("/personal");
                        }
                        return json({});
                    },
                },
                {
                    element: <FarmComponent history={history} />,
                    hydrateFallbackElement: <LoadingDialog />,
                    errorElement: <LoadingDialog />,
                    id: "with-tos",
                    handle: {
                        wiseWait: true, // so we do the redirect to '/tos' before executing other loaders
                    },
                    loader: async (_, context) => {
                        // https://github.com/remix-run/react-router/issues/11129
                        if (!context.root.politicas_aceptadas) {
                            return redirect("/tos");
                        }
                        return json({});
                    },
                    children: [
                        {
                            element: null,
                            id: "dummy",
                            index: true
                        }
                    ]
                }
            ]
        }
    ]);

    if (!router.navigate.hack) {
        const navigate_orig = router.navigate;
        const navigate = (...params) => {
            putLog(JSON.parse(JSON.stringify(params)));
            navigate_orig(...params);
        };
        navigate.hack = true;
        router.navigate = navigate;
    }
    return router;
};
const App = () => {
    // loader functions will be called inmediatly when createBrowserRouter is called, if the route is correct
    // to prevent failing because we have no auth-data, defer the router creation to the mounting of the app component
    const [routerInfo, setRouterInfo] = useState({ router: null, idx: 0 });

    useEffect(() => {
        const router = getRouter();
        setRouterInfo((ri) => ({ router, idx: (ri.idx + 1) }));

        // we need to clear the memory of the previous router
        return () => {
            router.dispose();
        };
    }, []);

    if (!routerInfo.router) return null;
    return <RouterProvider
        key={routerInfo.idx} router={routerInfo.router}
        future={{ v7_startTransition: true }}
    />;
};

function Layout({ history }) {
    const revalidate = useRevalidator();
    const navigation = useNavigation();

    return <ErrorBoundary name="Root">
        {(navigation.state === "loading" || revalidate.state === "loading") && <LoadingDialog />}
        <ToastContainer icon={false} theme="colored" />
        <Suspense fallback={<LoadingDialog />}>
            <div className="control-section">
                <TypesProvider>
                    <FarmProvider>
                        <div>
                            <GATracker history={history} />
                            <Outlet />
                        </div>
                    </FarmProvider>
                </TypesProvider>
            </div>
        </Suspense>
    </ErrorBoundary>;
}

const FarmComponent = ({ history }) => {
    const usuarioInfo = useRouteLoaderData("root");

    const selectorRef = useRef(null);
    const mapRef = useRef(null);
    const [dataSource, setDataSource] = useState({ loading: false, data: null });
    const [fields, setFields] = useState(null);
    const [menuIsOpen, setMenuIsOpen] = useState(null);
    const historyRef = useRef(null);
    historyRef.current = history;

    const { refreshUser, signOut, userInfo } = useContext(AuthenticatorContext);
    const [ds] = useContext(FarmContext);

    const isBilling = useMatch("/billing/*");

    const personalInfo = useMemo(() => {
        const newPersonalInfo = { ...usuarioInfo };

        if (userInfo && newPersonalInfo) {
            if (userInfo.name) {
                newPersonalInfo.nombre = userInfo.name;
            }
            if (userInfo.family_name
        && userInfo.family_name !== `${newPersonalInfo.apellido_paterno || ""} ${newPersonalInfo.apellido_materno || ""}`) {
                const idx = userInfo.family_name.indexOf(" ");
                const splits = idx > -1 ? [userInfo.family_name.slice(0, idx), userInfo.family_name.slice(idx + 1)]
                    : [userInfo.family_name, ""];
                newPersonalInfo.apellido_paterno = splits[0] || "";
                newPersonalInfo.apellido_materno = splits[1] || "";
            }
        }

        return newPersonalInfo;
    }, [usuarioInfo, userInfo]);

    return (<>
        <div id="wrapper" className={(ds?.dataFarm?.acceso_limitado ? "acceso_limitado " : "") + (!menuIsOpen ? "sidebar-closed" : "sidebar-open")}>
            <Header dataUser={personalInfo} userInfo={userInfo} signOut={signOut}
                toggleSidebar={() => setMenuIsOpen(c => !c)}
            />
            {
                isBilling ?
                    <BillingMenubar setMenuIsOpen={setMenuIsOpen} />
                    :
                    <Menubar historyRef={historyRef} innerRef={selectorRef} mapRef={mapRef} userInfo={userInfo}
                        fields={fields} setFields={setFields} dataSource={dataSource} setDataSource={setDataSource} menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen}
                    />
            }
            <div id="sidebar-content">
                <ErrorBoundary name="Route" resetOnChange>
                    <Outlet context={{
                        personalInfo: personalInfo,
                        refreshUser: refreshUser,
                        usuarioInfo: usuarioInfo,
                        accountsData: dataSource.data,
                        fields: fields,
                        menuIsOpen: menuIsOpen,
                        mapRef: mapRef,
                        selectorRef: selectorRef
                    }}
                    />
                </ErrorBoundary>
            </div>
        </div>
    </>);
};

export default withAuthenticator(App, Login, LoadingDialog);

// at least for now, preload all routes on application load
Promise.all(dynamicRoutes.map(cr => cr.getChildren())).catch(e => console.error(e));
