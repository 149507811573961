import withName from "../../hoc/withName";
import { Dropdown } from "primereact/dropdown";

const DropdownPrime = ({ accountDD=null, value = null,filter=true, options=null, setValue=null,onChange=null, optionValue=null, optionLabel=null,small=null,showClear=true, ...dropdownProps }) => {
    return (
        <>
            <Dropdown
                value={value} optionValue={optionValue} optionLabel={optionLabel}
                className={accountDD?"dropdown-prime-account":`dropdown-prime${small?" small":""}`}
                filter={filter} showClear={showClear} panelClassName={`dropdown-panel-prime${accountDD?"-account":""}`}
                onChange={onChange?onChange:((e) => {console.log(e);setValue?.(e.value);})} options={options} {...dropdownProps}
            />
        </>
    );
};

export default withName(DropdownPrime, "DropdownPrime");
