import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { matchPath, useLocation, useNavigate, useNavigationType } from "react-router";
import { useTranslation } from "react-i18next";

import { useMatchMedia } from "primereact/hooks";
import { PanelMenu } from "primereact/panelmenu";

import { faList, faUserCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { setTreeState } from "../Menubar/FarmTreeView";
import { putLog } from "../../utils/logcache";

const nodeNav = (node, navigate) => {
    try {
        const node_id = node && node.id;

        const [object_id, parent] = node_id?.split("_") || [];
        const expanded = { state: { expanded: [object_id] } };

        if (object_id === "priceList") {
            navigate({ pathname: "/billing/priceList" }, expanded);
        } else if (object_id === "clientparams") {
            navigate({ pathname: "/billing/clientparams" }, expanded);
        } else {
            return false;
        }

        return true;
    } catch (error) {
        console.error(error);
    }
};

const SelectedItemContext = createContext(null);

const TranslateElementsPrime = ({ data, setSelectedItem }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const selectedItem = useContext(SelectedItemContext);
    const [objeto_id] = data?.id?.split("_") ?? [];

    if (data === null || data === undefined || data.id === null) return null;
    const [objeto] = (data.id && data.id.split("-")) || [];

    return(
        <div className="toogleIconwrapper">
            <div
                onClick={(args) => {
                    const [objeto_id] = data?.id?.split("_") ?? [];
                    const navigated = nodeNav(data, navigate);
                    if (navigated) setSelectedItem(objeto_id);
                    args.stopPropagation();
                }}
                className={`treeview-texts-prime
          ${data.level !== 1 && data.tag !== "title" ? "normal" : ""}
          ${data.tag === "title" && data.level !== 1 ? "title" : ""}
          ${(objeto_id && selectedItem === objeto_id) ? "activeNode" : ""}`}
            >
                <span className="menu-icon-external">
                    <FontAwesomeIcon className="faIcon" size="lg" icon={data.icon} />
                </span>
                <span className="menu-icons-prime" /><p className="orden-solo uppercase">{t(`billing.sideBar.${data.name}`)}</p>
            </div>
        </div>
    );
};

const treeData = [
    {
        id: "priceList",
        name: "priceList",
        icon: faList,
        level: 1,
        tag: "title"
    },{
        id: "clientparams",
        name: "clientParams",
        icon: faUserCog,
        level: 1,
        tag: "title"
    },
    /*{
    id: 'documents',
    name: "documents.name",
    icon: faFile,
    level:1,
    tag:"title",
    subChild: [
      { id: 'docFarm', name: "documents.farm"},
      { id: 'docClient', name: "documents.client"},
      { id: 'docIntegrator', name: "documents.integrator"},
    ],
  },*/
];

const StateReseter = ({ setSelectedItem }) => {
    const menuTreeNodes = treeData;
    const action = useNavigationType();
    const location = useLocation();

    useEffect(() => {
        const { pathname, state } = location || {};

        if (state && (action === "POP" || state.updateTree)) {
            setTreeState(menuTreeNodes, null, setSelectedItem, state && state.expanded);
        } else if (action && matchPath("/billing/personal/", pathname)) {
            // clean selected nodes if, for example, we are in "/personal" or "/account"
            setSelectedItem(null);
        }

        putLog("new loc:" + pathname + ", action: " + action, 2);
    }, [menuTreeNodes, location, action]);

    return null;
};

const BillingMenubar = ({ setMenuIsOpen }) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const mediaQuery = "(min-width: 600px)";

    const isMobile = !useMatchMedia(mediaQuery);
    useEffect(() => {
        setMenuIsOpen(!isMobile);
    }, [isMobile]);

    const model = useMemo(() => {
        const nodeTemplate = (data) => <TranslateElementsPrime data={data} setSelectedItem={setSelectedItem} />;

        treeData.forEach(t => t.template = nodeTemplate);
        return treeData;
    }, []);

    return (
        <div id="sidebar-menu" className="sidebar-content treeview-container treeview-nomax main-menu">
            <StateReseter setSelectedItem={setSelectedItem} />
            <SelectedItemContext.Provider value={selectedItem}>
                <PanelMenu
                    id="treeviewbilling"
                    model={model}
                    multiple
                />
            </SelectedItemContext.Provider>
        </div>
    );
};

export default BillingMenubar;
