const switchTipoNodo = (tipo,sub_tipo) => {
    switch (tipo) {
        case 1:
        case 2:
            return "RF-X1";
        case 3:
            return "CIMIS";
        case 4:
            return "FTP";
        case 6:
            return "Davis";
        case 7:
        case 8:
            if(sub_tipo ==="lite"){
                return "RF-C1 Lite";
            }
            return "RF-C1";
        case 9:
            return "RF-M1";
        case 10:
            return "RF-M1-3G";
        case 11:
            return "Pessl";
        case 12:
            return "JohnDeere Field Connect";
        case 13:
            return "CropConnect";
        case 16:
            switch(sub_tipo) {
                case 34:
                    return "RF-V1-2Latch";
                case 35:
                    return "RF-V1-4Latch";
                case 0:
                default:
                    return "RF-V1";
            }
        default:
            return "";
    }
};

export default switchTipoNodo;
