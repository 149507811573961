import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useLocation } from "react-router";

import { Menubar } from "primereact/menubar";
import ErrorBoundary from "../Utils/ErrorBoundary";
/* import './Header.css'; */
/* import '../../scss/layouts.scss'; */
/* import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons'; */


const redirectPersonal = (navigate) => {
    const path = "/personal";
    if (path !== window.location.pathname) {
        if(window.location.pathname.startsWith("/billing")){
            navigate({ pathname: `/billing${path}` });
        }else{
            navigate({ pathname: path });
        }
    }
};
const redirectBilling = (navigate) => {
    const path = "/billing";
    if (path !== window.location.pathname) {
        navigate({ pathname: path });
    }
};
const redirectAdmin = (navigate) => {
    const path = "/";
    if (path !== window.location.pathname) {
        navigate({ pathname: path });
    }
};
const Header = ({ dataUser, userInfo, signOut }) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const showName = useMemo(() => {
        const uInfo = userInfo || {};
        const dUser = dataUser || {};
        return `${uInfo.name || dUser.nombre} ${uInfo.family_name || dUser.apellido_paterno}`;
    }, [userInfo, dataUser]);

    const itemAction = ({item}) => {
        if (!item || !item.action) return;
        const match = item.action.match(/^([^-]+)-(.*)/);
        const action = match && match[1] || item.action;

        if (action === "signout") {
            signOut().then(() => redirectPersonal(navigate));
        } else if (action === "personal") {
            redirectPersonal(navigate);
        } else if (action === "lang") {
            if (i18n.language === match[2]) return;

            // setTimeout to execute after the event handler
            setTimeout(() => {
                i18n.changeLanguage(match[2]);
            }, 0);
        }else if(action === "Billing"){
            redirectBilling(navigate);
        }else if(action === "Admin"){
            redirectAdmin(navigate);
        }
    };
    const billingItem = {
        iconCss: location.pathname.startsWith("/billing") ? "admin-icon" : "billing-icon",
        text: location.pathname.startsWith("/billing") ? t("billing.headerAd") : t("billing.headerWb"),
        action:location.pathname.startsWith("/billing") ? "Admin" : "Billing",
        command:itemAction
    };
    const langItem = {
        iconCss: "world-icon",
        text: t("i18n.current"),
        items: [
            { text: t("i18n.english"), action: "lang-en" },
            { text: t("i18n.spanish"), action: "lang-es" },
            { text: t("i18n.italian"), action: "lang-it" },
            { text: t("i18n.turkish"), action: "lang-tr" },
            { text: t("i18n.french"), action: "lang-fr" },
        ]
    };
    const langItemSmall = {
        iconCss: "world-icon-small",
        /*  text: t("i18n.current"), */
        items: [
            { text: t("i18n.english"), action: "lang-en" },
            { text: t("i18n.spanish"), action: "lang-es" },
            { text: t("i18n.italian"), action: "lang-it" },
            { text: t("i18n.turkish"), action: "lang-tr" },
            { text: t("i18n.french"), action: "lang-fr" },
        ]
    };
    const personalItem = {
        iconCss: "user-icon",
        text: showName,
        items: [
            { text: t("header.personalInfo"), action: "personal" },
            { text: t("header.signout"), action: "signout" }
        ]
    };
    const personalItemSmall = {
        iconCss: "user-icon-small",
        /* text: showName, */
        items: [
            { text: t("header.personalInfo"), action: "personal" },
            { text: t("header.signout"), action: "signout" }
        ]
    };

    const billingItemPrime = {
        icon: location.pathname.startsWith("/billing") ? "admin-icon" : "billing-icon",
        label: location.pathname.startsWith("/billing") ? t("billing.headerAd") : t("billing.headerWb"),
        action:location.pathname.startsWith("/billing") ? "Admin" : "Billing",
        command:itemAction
    };
    const langItemPrime = {
        icon: "world-icon",
        label: t("i18n.current"),
        items: [
            { label: t("i18n.english"), action: "lang-en", command:itemAction},
            { label: t("i18n.spanish"), action: "lang-es", command:itemAction },
            { label: t("i18n.italian"), action: "lang-it", command:itemAction },
            { label: t("i18n.turkish"), action: "lang-tr" , command:itemAction},
            { label: t("i18n.french"), action: "lang-fr", command:itemAction },
        ]
    };
    const personalItemPrime = {
        icon: "user-icon",
        label: showName,
        className:"subListFull",
        items: [
            { label: t("header.personalInfo"), action: "personal", command:itemAction },
            { label: t("header.signout"), action: "signout", command:itemAction }
        ]
    };
    return (<>
        {dataUser?.comercial || location.pathname.startsWith("/billing")?
            <Menubar className="header-popup d-none d-md-block primeMenuBar"
                model={[{...billingItemPrime},{...langItemPrime}, {...personalItemPrime}]}
            />
            :
            <Menubar className="header-popup d-none d-md-block primeMenuBar"
                model={[{...langItemPrime}, {...personalItemPrime}]}
            />}
        {dataUser?.comercial || location.pathname.startsWith("/billing")?
            <Menubar menuIcon={<span className='pi pi-bars menuIcon' />} className="header-popup-small d-block d-md-none primeMenuBar"
                model={[{...billingItemPrime},
                    {...langItemPrime, icon: langItemPrime.icon + " small"},
                    {...personalItemPrime, icon: personalItemPrime.icon + " small"}]}
            />
            :
            <Menubar menuIcon={<span className='pi pi-bars menuIcon' />} className="header-popup-small d-block d-md-none primeMenuBar"
                model={[{...langItemPrime, icon: langItemPrime.icon + " small"},
                    {...personalItemPrime, icon: personalItemPrime.icon + " small"}]}
            />}
    </>);
};

const HeaderLayout = (props) => {
    return (
        <div className="header-section" id="header">
            <ul className="header-list">
                <li id="hamburger" className="icon-menu icon" onClick={props && props.toggleSidebar} />
                {/* <div className="header-title d-none d-lg-block"></div>
        <div className="header-title-small d-none d-sm-block d-lg-none"></div>
        <div className="header-title-muy-small d-block d-sm-none "></div> */}
                <div className="header-title d-none d-lg-block" />
                <div className="header-title-small d-block d-lg-none" />
                <div className="header-title-muy-small d-block" />

                <li className="right-header">
                    <div className="horizontal-menu">
                        <ErrorBoundary name="Header"><Header {...props} /></ErrorBoundary>
                    </div>
                </li>
            </ul>
        </div>
    );
};

HeaderLayout.displayName="HeaderLay";
export default HeaderLayout;
